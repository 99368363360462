const isProd = process.env.NODE_ENV === "production";

export default {
  IsProd() {
    // return true;
    return isProd;
  },
  get ElApiKey() {
    return "26c9aeb3-929e-48fc-8be5-cc87ef311621";
  },
  get URL() {

    return ((this.IsProd()) ? "https://Eva.eclock.com.mx/" : "http://localhost:8090/");
    
  },

  //Ruta cuando tenemos que confirmar un usuario
  get URLConfirmacion() {
    return `${this.URL}confirmacion/`;
  },
  //Ruta cuando tenemos la autentificación confirmada
  get URLAutentificacion() {
    return `${this.URL}auth/`;
    //return "http://localhost:8090/auth/";
  },
  get UsarSignalR() {
    return false;
  },
  URLPersona(PersonaID) {
    return `${this.URL}Personas/${PersonaID}`;
  },
}
