<template>
  <div class="wrapper" :class="{ 'nav-open': false }">
    <notifications></notifications>


    <div class="main-panel" :data="sidebarBackground">
      <home-navbar :FotoURL="FotoURL" :Nombre="Nombre" :SuscripcionesUsuario="SuscripcionesUsuario" :soporteURL="soporteURL"></home-navbar>
      
        <router-view name="header"></router-view>
    
      <div :class="{ content: false }" 
           @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <div class="mt-5 mx-2">
            <router-view v-if="!Suspendido"></router-view>

            <base-alert v-else type="danger">
              <strong>Alerta</strong> Esta suscripción esta suspendida, conectata a atención a cliente.
            </base-alert>
          </div>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  import Usuarios from '@/api-base/usuarios.service';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import HomeNavbar from './HomeNavbar.vue';
  import ContentFooter from './ContentFooter.vue';

  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import { ZoomCenterTransition } from 'vue2-transitions';
  import Menu from './Menu';

  export default {
    components: {
      HomeNavbar,
      ContentFooter,
      ZoomCenterTransition,
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
        error: '',
        trabajando: false,

        MostrarEmbajador: false,
        Suspendido: false,

        FotoURL: '/img/default-avatar.png',
        url: 'https://www.eClock.com.mx',
        soporteURL:'https://support.eclock.com.mx/portal/es/kb/eclock',
        Nombre: '',
        SuscripcionesUsuario: [],
        Menu:[],
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        console.debug(navigator.platform);
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
        //  return;
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('sidebar');
          initScrollbar('main-panel');
          initScrollbar('sidebar-wrapper');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    },
    mounted() {
      this.trabajando = true;
      Usuarios.getPerfilLocal((UsuarioPerfil) => {
        console.debug(UsuarioPerfil);

        this.url = `https://www.eClock.com.mx?&siq_name=${UsuarioPerfil.usuario.nombre}&siq_email=${UsuarioPerfil.usuario.correo}`;
        this.soporteURL = `https://support.eclock.com.mx/portal/es/kb/eclock?&siq_name=${UsuarioPerfil.usuario.nombre}&siq_email=${UsuarioPerfil.usuario.correo}`;
        //soporteURL: 'https://support.eclock.com.mx/portal/es/kb/eclock'
        ///https://github.com/Microsoft/ApplicationInsights-JS/issues/571
        //El usuario seleccioinado, no estoy seguro de la opción correcta
        this.$appInsights.context.user.id = `${UsuarioPerfil.usuario.usuarioID}`;        
        this.$appInsights.context.user.userId = `${UsuarioPerfil.usuario.usuarioID}`;

        //this.$appInsights.context.user.user_AuthenticatedId = `${UsuarioPerfil.usuario.usuarioID}`;
        //La suscripción seleccionada
        this.$appInsights.context.user.accountId = localStorage.suscripcionID;
       // this.$appInsights.config.accountId = UsuarioPerfil.usuario.usuarioID;
        if (UsuarioPerfil.usuario.urlFotoMiniatura != null && UsuarioPerfil.usuario.urlFotoMiniatura != "") {
          this.FotoURL = UsuarioPerfil.usuario.urlFotoMiniatura;
        }

        var Componentes = UsuarioPerfil.usuario.nombre.split(" ");
        this.Nombre = Componentes[0];

        console.debug("HomeLayOut.Actualizado");
        this.SuscripcionesUsuario = UsuarioPerfil.usuario.suscripcionesUsuario;
        //console.debug(this.SuscripcionesUsuario);
        ///No tiene suscripción
        if (UsuarioPerfil.usuario.suscripcionesUsuario < 1) {
          var Ubicacion = window.location.href;
          //console.debug(Ubicacion);
          ///Si es un usuario nuevo
          /*  if (Ubicacion.indexOf("usuarios/nuevo") > 0)
              return;*/
          //var Pos = Ubicacion.indexOf("/");
          // var NUbicacion = Ubicacion.slice(0, Pos) +
          var NUbicacion = "/#/suscripciones/pregunta";
          //console.debug(NUbicacion);

          window.location.href = NUbicacion;
          // console.debug(Ubicacion.slice(0, Pos) + "/dashboard");
          //Ubicacion = Ubicacion.replace("index.html", "");
          return;
        }

        var Suscripcion = SuscripcionesUsuarios.getSuscripcion();
        
        var SU = SuscripcionesUsuarios.getSuscripcionUsuario();
        this.MostrarEmbajador = Suscripcion.esEmbajador;
        this.Menu = Menu.ObtenMenu(SU.perfilID, this.MostrarEmbajador);


        // console.debug(response.data);
        this.Suspendido = Suscripcion.borrado; 
        this.trabajando = false;

      });
      this.initScrollbar();

    }
  };
</script>
<style lang="scss">
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from

  {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }

  }

  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }

  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }

  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
</style>
